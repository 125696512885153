import SingleAppFeature from "./SingleAppFeature";

const index = () => {
  return (
    <section className="relative bg-light-base py-24 dark:bg-jacarta-800">
      <div className="container">
        <div className="mx-auto mb-20 max-w-xl text-center">
          <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
            Aprende inglés para programadores de una manera única e innovadora
          </h2>
          <p className="text-lg dark:text-jacarta-300">
            Nuestro enfoque se basa en enseñar inglés de la vida real y situaciones específicas para los desarrolladores, con ejercicios prácticos y una comunidad activa. Descubre una experiencia de aprendizaje enriquecedora y efectiva.
          </p>
        </div>

        <div className="grid gap-7 md:grid-cols-3">
          <SingleAppFeature />
        </div>

        <p className="mt-4 text-center text-lg dark:text-jacarta-300">
          Con Fluenn, siempre estarás en el camino correcto para aprender inglés.{" "}
          <a href="#" className="text-accent">
            Conoce más.
          </a>
        </p>
      </div>
    </section>
  );
};

export default index;
