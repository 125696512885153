export const testimonial_data = [
  {
    title: 'Desarrollador de software',
    desc: "La app de Fluenn me ha ayudado a mejorar mi inglés en poco tiempo. Ahora puedo comunicarme mejor con mis colegas internacionales.",
    id: '0Desarrollador de software',
    name: 'Juan Pérez',
    img: '/images/testimonials/testimonial_1.jpg',
  },
  {
    title: 'Diseñadora web',
    desc: "Fluenn ha sido fundamental para entender la documentación en inglés. He podido aplicar lo aprendido en mis proyectos personales y laborales.",
    id: '1Diseñadora web',
    name: 'Laura García',
    img: '/images/testimonials/testimonial_2.jpg',
  },
  {
    title: 'Ingeniero de sistemas',
    desc: "Fluenn ha cambiado mi vida profesional. Ahora puedo participar en conferencias y eventos en inglés sin preocuparme por la barrera del idioma.",
    id: '2Ingeniero de sistemas',
    name: 'Carlos Rodríguez',
    img: '/images/testimonials/testimonial_3.jpg',
  },
  {
    title: 'Analista de datos',
    desc: "Gracias a Fluenn, he podido mejorar mis habilidades de escritura en inglés. Me siento más seguro al escribir correos electrónicos y documentación técnica.",
    id: '3Analista de datos',
    name: 'Sofía Torres',
    img: '/images/testimonials/testimonial_4.jpg',
  },
  {
    title: 'Programador full-stack',
    desc: "La app de Fluenn ha sido una herramienta increíble para aprender inglés. Los ejercicios especializados en programación me han ayudado a ampliar mi vocabulario técnico.",
    id: '4Programador full-stack',
    name: 'Diego Guzmán',
    img: '/images/testimonials/testimonial_5.jpg',
  },
  {
    title: 'Gerente de proyectos',
    desc: "Fluenn me ha permitido mejorar la comunicación con mi equipo global. Ahora puedo liderar proyectos internacionales con mayor confianza.",
    id: '5Gerente de proyectos',
    name: 'Ana Castillo',
    img: '/images/testimonials/testimonial_6.jpg',
  },
  {
    title: 'Consultor de TI',
    desc: "La app de Fluenn es una excelente opción para aprender inglés en el mundo de la tecnología. He podido aplicar lo aprendido en mis actividades laborales diarias.",
    id: '6Consultor de TI',
    name: 'Fernando Ramírez',
    img: '/images/testimonials/testimonial_7.jpg',
  },
  {
    title: 'Desarrollador de aplicaciones móviles',
    desc: "Fluenn me ha ayudado a mejorar mi pronunciación en inglés. Ahora puedo comunicarme con mis clientes internacionales sin preocuparme por malentendidos.",
    id: '7Desarrollador de aplicaciones móviles',
    name: 'Marta Cordero',
    img: '/images/testimonials/testimonial_8.jpg',
  }
]