import HelpBlock from "./HelpBlock";

const index = () => {
  return (
    <section className="relative bg-light-base py-24 dark:bg-jacarta-800">
      <div className="container">
        <div className="items-center justify-between lg:flex lg:space-x-24">
          <div className="lg:w-[45%]">
            <h2 className="mb-6 font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              ¿Necesitas ayuda?
            </h2>
            <p className="mb-6 text-lg dark:text-jacarta-300">
              ¡Nuestra aplicación móvil enseña inglés a programadores y está disponible exclusivamente para iOS! Con esta app, puedes acceder a cursos, clases, escuchar y repetir frases. Además, la app evalúa tu pronunciación, ofrece ejercicios de escritura y vocabulario especializado para programadores.
            </p>
            <p className="dark:text-jacarta-300">
              Aprovecha al máximo tu experiencia de aprendizaje con nuestra aplicación. Desarrolla tus habilidades de inglés para comunicarte de manera más efectiva en el ámbito de la programación y mejora tu carrera profesional.
            </p>
          </div>
          <div className="lg:w-[55%]">
            <HelpBlock />
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
