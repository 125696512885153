const HelpBlock = () => {
  return (
    <>
      <div className="mt-12 md:flex md:space-x-8 lg:justify-end">
        <div className="relative mb-8 flex-1 self-end rounded-2.5xl bg-green p-8 shadow-2xl">
          <div className="absolute right-4 top-4">
            {/* ... SVG icon for Blog ... */}
          </div>
          <div>
            <span className="mb-2 block font-display text-lg text-white">
              Cursos y lecciones
            </span>
            <p className="mb-4 text-white">
              Accede a nuestro contenido especializado y aprende inglés con cursos y lecciones diseñadas para programadores.
            </p>
            <a href="#" className="font-bold text-white underline">
              Descubre más
            </a>
          </div>
        </div>
        <div className="relative mb-8 flex-1 self-end rounded-2.5xl bg-accent p-8 py-16 shadow-2xl">
          <div className="absolute right-4 top-4">
            {/* ... SVG icon for 24/7 Chat Support ... */}
          </div>
          <div>
            <span className="mb-2 block font-display text-lg text-white">
              Evaluación de pronunciación
            </span>
            <p className="mb-4 text-white">
              Practica y mejora tu pronunciación con nuestra función de escuchar y repetir. La app evaluará tu desempeño y te dará retroalimentación.
            </p>
            <a href="#" className="font-bold text-white underline">
              Comienza ahora
            </a>
          </div>
        </div>
      </div>
      {/* End ."mt-12 */}

      <div className="relative">
        <div className="relative mx-auto self-start rounded-2.5xl bg-blue p-8 shadow-2xl md:max-w-xs">
          <div className="absolute right-4 top-4">
            {/* ... SVG icon for FAQs ... */}
          </div>

          <div>
            <span className="mb-2 block font-display text-lg text-white">
              Ejercicios de escritura y vocabulario
            </span>
            <p className="mb-4 text-white">
              Mejora tus habilidades de escritura y amplía tu vocabulario de programación en inglés con nuestros ejercicios interactivos.
            </p>
            <a href="#" className="font-bold text-white underline">
              Empieza a practicar
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpBlock;
