const SingleAppFeature = () => {
  const singleAppContent = [
    {
      id: 1,
      icon: "w1",
      title: "Inglés enfocado en programación",
      text: `Aprende inglés específicamente diseñado para situaciones y conversaciones que los desarrolladores enfrentan en su día a día.`,
    },
    {
      id: 2,
      icon: "w2",
      title: "Práctica de habilidades esenciales",
      text: `Mejora tus habilidades de escucha, habla y escritura en inglés a través de ejercicios prácticos y ejemplos de la vida real.`,
    },
    {
      id: 3,
      icon: "w3",
      title: "Comunidad de apoyo",
      text: `Únete a nuestra comunidad de programadores que también están aprendiendo inglés, y comparte experiencias, consejos y apoyo mutuo.`,
    },
  ];
  return (
    <>
      {singleAppContent.map((item) => (
        <div
          className="mb-12 rounded-2.5xl border border-jacarta-100 bg-white p-8 pt-0 text-center transition-shadow hover:shadow-xl dark:border-jacarta-600 dark:bg-jacarta-700"
          key={item.id}
        >
          <div className="mb-9 -mt-8 inline-flex h-[5.5rem] w-[5.5rem] items-center justify-center rounded-full border border-jacarta-100 bg-white dark:border-jacarta-600 dark:bg-jacarta-700">
            <img
              src={`/images/crypto-app/${item.icon}.svg`}
              alt="icon"
              width="24"
              height="24"
              className="h-12 w-12"
            />
          </div>

          <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
            {item.title}
          </h3>
          <p className="dark:text-jacarta-300">{item.text}</p>
        </div>
      ))}
    </>
  );
};

export default SingleAppFeature;
